import "react-image-lightbox/style.css";

import LightBox from "react-image-lightbox";
import React from "react";

import { useEffect, useState, useCallback } from "react";

const GalleryBlock = ({ data }) => {
    const [showLightBox, setShowLightBox] = useState(false);
    const [lightBoxImages, setLightBoxImages] = useState({});

    // Utils
    const getLightBoxImages = useCallback((image) => {
        let imageIndex = 0;
        if (image) {
            imageIndex = data.images.indexOf(image);
        }

        return {
            previous: data.images[imageIndex - 1],
            main: data.images[imageIndex],
            next: data.images[imageIndex + 1],
        };
    }, [data.images])

    const getImages = () => {
        return data.images;
    };

    // Effect
    useEffect(() => {
        if (!data.images.length) {
            return;
        }

        // Set initial images
        setLightBoxImages(getLightBoxImages());
    }, [data.images, getLightBoxImages]);

    // Actions
    const handleImageClick = (e, image) => {
        e.preventDefault();
        // Select images to show
        setLightBoxImages(getLightBoxImages(image));
        setShowLightBox(true);
    };

    const handleLightBoxClose = () => {
        setShowLightBox(false);
    };

    const handleLightBoxNext = () => {
        const image = lightBoxImages.next;
        setLightBoxImages(getLightBoxImages(image));
    };

    const handleLightBoxPrevious = () => {
        const image = lightBoxImages.previous;
        setLightBoxImages(getLightBoxImages(image));
    };

    // Fast escape
    if (!data.images) {
        return <></>;
    }

    return (
        <>
            <section className="gallery">
                <div className="gallery__container">
                    <h2>{data.title}</h2>
                    <div className="gallery__list">
                        {getImages().map((image, index) => {
                            return (
                                <div className="gallery__item" key={index}>
                                    <a href="/" onClick={(e) => handleImageClick(e, image)}>
                                        <div className="gallery__item__media">
                                            <img src={image.file.url} className="gallery__item__media-content" />
                                        </div>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            {Boolean(showLightBox) && (
                <LightBox
                    mainSrc={lightBoxImages.main?.file.url}
                    nextSrc={lightBoxImages.next?.file.url}
                    prevSrc={lightBoxImages.previous?.file.url}
                    onCloseRequest={handleLightBoxClose}
                    onMoveNextRequest={handleLightBoxNext}
                    onMovePrevRequest={handleLightBoxPrevious}
                />
            )}
        </>
    );
};

export default GalleryBlock;


import React from "react";

const Sponsors = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    return (
        <section className="sponsors">
            <div className="sponsors__title">
                <h2>{data.title}</h2>
            </div>
            <div className="sponsors__wrapper">
                {data.sponsers.map((sponsor, key) => {
                    return <a className="sponsors__item" key={key} href={sponsor.websiteUrl} target="_blank" title={`Ga naar website van ${sponsor.name}`}>
                        <img src={sponsor.image.file.url} alt="" />
                    </a>
                })}
            </div>
        </section>
    );
};

export default Sponsors;

import { Link } from "gatsby";
import React from "react";

const Hero = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    return (
        <section className="hero" style={{ backgroundImage: `url(${data.heroImage.file.url})` }}>
            <div className="hero__container">
                <h3 className="hero__title">{data.title}</h3>
                <p className="hero__text">
                    {data.introText}
                </p>
                <div className="hero__actions">
                    <Link className="hero__actions--item" to={data.videoLink}>
                        {data.button1text}
                    </Link>
                    <a className="hero__actions--item" href={`/${data.meerInfoLink}`}>
                        {data.button2text}
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Hero;

import TextBlockWithImage from "../components/textBlockWithImage";
import TextBlock from "../components/textBlock";
import SingleImage from "../components/singleImage";
import Location from "../components/location";
import Cards from "../components/cards";
import GalleryBlock from "../components/galleryBlock";
import Hero from "../components/hero";
import TeamGroup from "../components/teamGroup";
import Sponsors from "../components/sponsors";
import Form from "../components/form";

const components = {
    "ContentfulTextBlockWithImage": TextBlockWithImage,
    "ContentfulSingleImage": SingleImage,
    "ContentfulTextBlock": TextBlock,
    "ContentfulLocation": Location,
    "ContentfulCards": Cards,
    "ContentfulGalleryBlock": GalleryBlock, 
    "ContentfulHero": Hero, 
    "ContentfulPersonGroup": TeamGroup, 
    "ContentfulSponsers": Sponsors, 
    "ContentfulForm": Form, 

};

export default (contentItems) => {
    const renderComponents = [];

    // Match CMS components with website components
    for (var contentItem of contentItems) {
        if (!components[contentItem.internal?.type]) {
            continue;
        }

        renderComponents.push({
            component: components[contentItem.internal.type],
            data: contentItem,
        });
    }

    return renderComponents;
};

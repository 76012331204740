import React from "react";

const SingleImage = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    return (
        <section className="single-image">
            <div className="single-image__container">
                <h2>{data.title}</h2>
                <div className="single-image__image__container">
                    <img src={data.image.file.url} alt={data.title} />
                </div>
            </div>
        </section>
    );
};

export default SingleImage;

import React, { useState } from "react";

import { Link } from "gatsby";
import { Sticky } from "react-sticky";

export default ({ items }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    // Actions

    const handleHamburgerClick = () => {
        setMenuOpen(!isMenuOpen);
    };


    // Render

    return (
        <Sticky>
            {({ style, isSticky, wasSticky, distanceFromTop }) => {
                // Sticky navigation
                let className = "nav";

                // Responsive navigation
                className = isMenuOpen ? "nav nav--active" : className;

                return (
                    <nav className={className} style={style}>
                        <button type="button" className="button--hamburger" onClick={handleHamburgerClick}>
                            <span className="button__icon"></span>
                        </button>

                        <div className="nav__logo">
                            <Link to="/">
                                <img src={`/img/logoIsshin.jpg`} alt="Logo" className="nav__logo-media"></img>
                            </Link>
                        </div>

                        <div className="nav__lists">
                            <ul className="nav__list">
                                {items.map((navigationItem, index) => (
                                    <li className="nav__listitem" key={index}>
                                        <Link
                                            to={`/${navigationItem.slug}` ?? "/"}
                                            title={navigationItem.title ?? ""}
                                            className="link link--plain"
                                        >
                                            {navigationItem.title ?? ""}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </nav>
                );
            }}
        </Sticky>
    );
};

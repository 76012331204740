import React from "react";
import { Link } from "gatsby";

const Cards = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    return (
        <section className="cards">
            <div className="cards__wrapper">
                {data.cards.map((card, key) => {
                    if (card.isExternalLink) {
                        return <figure className="cards__item" key={key}>
                            <a href={`${card.link}`} target="__blank" className="link link--plain">
                                <div className="cards__item__header">
                                    <img src={card.image.file.url} alt={card.image.title} />
                                </div>
                                <figcaption className="cards__item__footer">

                                    <h4 className="cards__item__title">{card.title}</h4>
                                </figcaption>
                            </a>
                        </figure>
                    }
                    else {
                        return <figure className="cards__item" key={key}>
                            <Link to={`/${card.link}`} className="link link--plain">
                                <div className="cards__item__header">
                                    <img src={card.image.file.url} alt={card.image.title} />
                                </div>
                                <figcaption className="cards__item__footer">

                                    <h4 className="cards__item__title">{card.title}</h4>
                                </figcaption>
                            </Link>
                        </figure>
                    }
                })}
            </div>
        </section>
    );
};

export default Cards;

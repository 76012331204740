import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types'

const TextBlockWithImage = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    let className = "text-block-image"

    if (data.textPositionRight) {
        className += ' text-block-image--left';
    }

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node) => {
                return <a href={node.data.uri} target={'_blank'} rel={'noopener noreferrer'}>{node.content[0].value}</a>;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const file = node.data.target.fields.file["en-US"].url;
                const title = node.data.target.fields.title["en-US"];
                return <a className="download__button" href={file} target={'_blank'} rel={'noopener noreferrer'}>
                    <img src={`/img/${title}.png`} alt="" /></a>
            },
            [BLOCKS.UL_LIST]: (node) => {
                return <ul> {node.content.map((listItem, key) => {
                    return <li key={key}> <i className="far fa-clock"></i> {listItem.content[0].content[0].value}</li>
                })}
                </ul>;
            },
        }
    }

    return (
        <section className={className}>
            <div className="text-block-image__container">
                <div className="text-block-image__title">
                    <h2>{data.title}</h2>
                </div>
                <div className="text-block-image__body">
                    <div className="text-block-image__image">
                        <img src={data.image.file.url} alt={data.title} />
                    </div>
                    <div className="text-block-image__text">
                        {documentToReactComponents(data.text.json, options)}
                    </div>
                </div>
                <hr />
            </div>
        </section>
    );
};

export default TextBlockWithImage;

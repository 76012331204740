import HtmlHeaders from "../components/htmlHeaders";
import React from "react";
import { Helmet } from "react-helmet";
import { StickyContainer } from "react-sticky";

const Layout = ({ children, seo }) => {

    return (
        <StickyContainer>
            <HtmlHeaders seo={seo} />
            {children}
        </StickyContainer>
    );
};

export default Layout;

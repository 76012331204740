import { Helmet } from "react-helmet";
import React from "react";


const HtmlHeaders = ({ seo }) => {
    return (
        <Helmet>
            <link rel="icon" href={`/img/favicon/favicon.ico`} />
            <link rel="apple-touch-icon" sizes="180x180" href={`/img/favicon/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`/img/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`/img/favicon-16x16.png`} />
            <link rel="manifest" href={`/img/favicon/site.webmanifest`} />


            <meta name="msapplication-TileColor" content="#0C0440"></meta>
            <meta name="theme-color" content="#0C0440"></meta>
            <title>{seo.seoTitle}</title>
            <meta name="description" content={seo.seoDescription.seoDescription} />
            <meta name="keywords" content={seo.seoKeywords.seoKeywords} />

            <meta property="og:title" content={seo.seoTitle} />
            <meta property="og:description" content={seo.seoDescription.seoDescription} />
            <meta property="og:url" content="http://www.isshinpoperinge.be" />
            <meta property="og:image" content={seo.seoImage.file.url} />
            <meta property="og:image:secure_url" content={seo.seoImage.file.url} />
            <meta property="og:site_name" content={seo.seoTitle} />

            <meta property="twitter:title" content={seo.seoTitle} />
            <meta property="twitter:description" content={seo.seoDescription.seoDescription} />
            <meta property="twitter:image" content={seo.seoImage.file.url} />

            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css"
            />
        </Helmet>
    );
};


export default HtmlHeaders;

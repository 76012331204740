import React from "react";

const Form = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    return (
        <section className="contact">
            <div className="contact__container">
                <h2>{data.title}</h2>
                <div className="contact__icons__wrapper">
                    <div className="contact__icons__item">
                        <div className="contact__icons__item__icon">
                            <span className="fas fa-map-marker-alt"></span>
                        </div>
                        <div className="contact__icons__item__text">
                            <p><span>Zaal: Ouderdomseweg 3, 8970 Poperinge</span></p>
                        </div>
                    </div>
                    <div className="contact__icons__item">
                        <div className="contact__icons__item__icon">
                            <span className="fas fa-map-marker-alt"></span>
                        </div>
                        <div className="contact__icons__item__text">
                            <p><span>Secretariaat: Abelestationsstraat 14 bus D/00, 8970 Poperinge</span></p>
                        </div>
                    </div>
                    <div className="contact__icons__item">
                        <div className="contact__icons__item__icon">
                            <span className="fa fa-phone"></span>
                        </div>
                        <div className="contact__icons__item__text">
                            <p><span>Tel: </span><a href="tel://1234567920">+32 472-59 90 91</a></p>
                        </div>
                    </div>
                    <div className="contact__icons__item">
                        <div className="contact__icons__item__icon">
                            <span className="fa fa-paper-plane"></span>
                        </div>
                        <div className="contact__icons__item__text">
                            <p><span>e-mail: </span><a href="mailto:info@isshinpoperinge.be">info@isshinpoperinge.be</a></p>
                        </div>
                    </div>
                    <div className="contact__icons__item">
                        <div className="contact__icons__item__icon">
                            <span className="fab fa-facebook"></span>
                        </div>
                        <div className="contact__icons__item__text">
                            <p><span>Inschrijven in de </span><a target="_blank" href="https://www.facebook.com/groups/212479958814031">Facebook groep</a></p>
                        </div>
                    </div>
                </div>
                <div className="contact__form__wrapper">
                    <div className="contact__form">
                        <h3 className="mb-4">{data.formTitle}</h3>
                        <form
                            method="POST"
                            id="contact"
                            name="contact"
                            className="contactForm"
                            method="post"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            netlify-honeypot="bot-field"
                        >
                            <input type="hidden" name="bot-field" />
                            <input type="hidden" name="form-name" value="contact" />
                            <div className="contact__form__row">
                                <div className="contact__form__col contact__form__col--small">
                                    <div className="form-group">
                                        <label className="label" htmlFor="name">Naam</label>
                                        <input type="text" className="form-control" name="name" id="name" placeholder="Naam" />
                                    </div>
                                </div>
                                <div className="contact__form__col contact__form__col--small">
                                    <div className="form-group">
                                        <label className="label" htmlFor="email">E-mail adres</label>
                                        <input type="email" className="form-control" name="email" id="email" placeholder="E-mail" />
                                    </div>
                                </div>
                                <div className="contact__form__col contact__form__col--large">
                                    <div className="form-group">
                                        <label className="label" htmlFor="subject">Onderwerp</label>
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Onderwerp" />
                                    </div>
                                </div>
                                <div className="contact__form__col contact__form__col--large">
                                    <div className="form-group">
                                        <label className="label" htmlFor="#">Uw bericht</label>
                                        <textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder="Uw bericht"></textarea>
                                    </div>
                                </div>
                                <div className="contact__form__col contact__form__col--large">
                                    <div className="form-group">
                                        <input type="submit" value="Verzenden" className="btn btn-primary" />
                                        <div className="submitting"></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="contact__form__image__wrapper">
                        <div className="contact__form__image" style={{ backgroundImage: `url(${data.image.file.url})` }}>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;

import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types'

const TextBlock = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node) => {
                return <a href={node.data.uri} target={'_blank'} rel={'noopener noreferrer'}>{node.content[0].value}</a>;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const file = node.data.target.fields.file["en-US"].url;
                const title = node.data.target.fields.title["en-US"];
                return <a className="download__button" href={file} target={'_blank'} rel={'noopener noreferrer'}>
                    <img src={`/img/pdf-document.png`} alt="" /></a>
            },
            // [BLOCKS.UL_LIST]: (node) => {
            //     return <ul> {node.content.map((listItem, key) => {
            //         return <li key={key}> <i className="far fa-clock"></i> {listItem.content[0].content[0].value}</li>
            //     })}
            //     </ul>;
            // },
        }
    }

    return (
        <section className="text-block">
            <div className="text-block__text">
                {documentToReactComponents(data.textBlock.json, options)}
            </div>
        </section>
    );
};

export default TextBlock;

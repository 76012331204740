import React from "react";

const TeamGroup = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }
    console.log(data);

    return (
        <section className="team">
            <div className="team__container">
                <h2 className="team__title">{data.title}</h2>
                <div className="team__members__wrapper">
                    {data.persons.map((x, key) => {
                        return <div className="team__members__item" key={key}>
                            {/* <p>{x.role.role}</p> */}

                            <div class="team__members__item__avatar">
                                <img src={x.image.file.url} alt="" />
                                <h4>{x.name}</h4>
                            </div>

                            <div class="team__members__item__details">
                                <div class="team__members__item__degree">
                                    <h5 class="team__members__item__title">Graad/diploma</h5>
                                    <ul>
                                        {x.degree?.map((d, key) => {
                                            return <li key={key}>
                                                <span>
                                                    {d}
                                                </span>
                                            </li>
                                        })}
                                    </ul>
                                </div>

                                <div class="team__members__item__functions">
                                    <h5 class="team__members__item__title">Functies</h5>
                                    <ul>
                                        {x.functions?.map((f, key) => {
                                            return <li key={key}>
                                                <span>
                                                    {f}
                                                </span>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>


                        </div>

                    })}
                </div>
            </div>
        </section>
    );
};

export default TeamGroup;

import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from './layout'
import parseComponents from "../utils/componentMapper";
import Navigation from "../components/navigation"



// export default BlogPostTemplate
const Page = ({ pageContext: { page, navigation, seo } }) => {

  // Parse components
  var renderComponents = parseComponents(page.contentItems);
  
  // Layout
  return (
    <Layout seo={seo}>
      <Navigation items={navigation} />
      {renderComponents.map((renderComponent, index) => {
        return <renderComponent.component key={index} page={page} data={renderComponent.data} />;
      })}
      {/* <Footer navigation={navigation} address={address} openingHours={openingHours} /> */}
    </Layout>
  );
};

export default Page;

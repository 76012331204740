import React from "react";

const Location = ({ data }) => {
    // Fast escape
    if (!data) {
        return <></>;
    }

    return (
        <section className="text-block-image--left">
            <div className="text-block-image__container">
                <div className="text-block-image__title">
                    <h2>{data.title}</h2>
                </div>
                <div className="text-block-image__body">
                    <div className="text-block-image__image">
                        <img src={data.image.file.url} alt={data.title} />
                    </div>
                    <div className="text-block-image__text">
                        <iframe width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=nl&amp;q=Stedelijke%20sporthal,%208970%20Poperinge,%20Belgi%C3%AB+(Isshin%20Poperinge)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.mapsdirections.info/nl/cirkel-straal-kaart/"></a>
                    </div>
                </div>
                <hr />
            </div>
        </section>
    );
};

export default Location;
